<template>
    <div>
        <div
            class="modal fade"
            v-bind:class="[opt.isShowModal]"
            v-bind:style="{ display: opt.displayModal }"
            id="searchPatient"
            tabindex="-1"
            aria-labelledby="searchPatientLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
        >
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content">
                    <div class="wrapper">
                        <div class="card" style="margin:10px;">
                            <div class="modal-body p-0">
                                <div class="desc">
                                    <div class="title mt-2">
                                        <p v-html="opt.caption" class="avenir-light"></p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="d-grid">
                                            <button class="btn btn-main-alt"  v-on:click="closeModal()">Tidak</button>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="d-grid">
                                            <button  v-on:click="onConfirm()" class="btn btn-default">Ya</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fade" v-bind:class="[opt.backdrop]"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            opt: this.options,
            capt:this.caption,
            conf:this.confirm
        }
    },
    props: {
        options: Object,
        caption:String,
        confirm:Boolean
    },
    model:{
        prop:"confirm",
        event:"load"
    },
    methods:{
        closeModal() {
            this.opt.isShowModal = '';
            this.opt.displayModal = 'none';
            this.opt.backdrop = '';
            this.conf = false
            this.$emit("on_listen_confirm",this.conf)
        },
        onConfirm() {
            this.opt.isShowModal = '';
            this.opt.displayModal = 'none';
            this.opt.backdrop = '';
            this.conf = true
            this.$emit("on_listen_confirm",this.conf)
        }
    }
}
</script>
<style scoped>
.btn-default {
    border: 1px solid #4d3180;
    color: #4d3180;
}
</style>
