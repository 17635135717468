<template>
    <div class="container-fluid">
        <div
            class="page-content ml-sm-0"
            id="page-content"
        >
            <div class="header-greeting mb-3">
                <p class="p-0 m-0">Recopedia</p>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <h1 class="fw-bolder">Pasien Detail</h1>
                    </div>
                    <div class="col-sm-12 col-md-6 text-md-end">
                        <button
                            class="btn btn-edit mb-2 px-4 me-3"
                            v-on:click="openModal()"
                        >
                            Edit Data
                        </button>
                            <button
                                v-on:click="back()"
                                type="button"
                                class="btn btn-add-main px-5 mb-2"
                            >
                                Kembali
                            </button>
                    </div>
                </div>
            </div>
            <section id="patient-details-card">
                <div
                    class="alert alert-danger"
                    role="alert"
                    v-bind:style="{ display: alert }"
                >
                    {{ alertMessage }}
                </div>
                <div class="card p-4">
                    <div v-if="loadPasien">
                        <h3 class="text-center">
                            <i class="fa fa-spinner fa-spin"></i>
                        </h3>
                    </div>
                    <template v-else>
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div
                                    class="label-1 avenir-light"
                                    style="color: #93b0c8"
                                >
                                    Orang Tua / Wali
                                </div>
                                <h3 class="title-text fw-bold">
                                    {{ namePatient }}
                                </h3>
                            </div>
                            <div class="col-12 col-md-6">
                                <div
                                    class="label-1 avenir-light"
                                    style="color: #93b0c8"
                                >
                                    Anak
                                </div>
                                <h3 class="title-text fw-bold">{{ name }}</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 col-sm-12 mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">
                                            No HP (WhatsApp)
                                        </div>
                                        <div class="detail-main-text">
                                            {{ phone }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-2 mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">Umur</div>
                                        <div class="detail-main-text">
                                            {{ umur }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-auto mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">
                                            Tanggal Lahir
                                        </div>
                                        <div class="detail-main-text">
                                            {{ date_birth }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-12 mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">
                                            Jenis Kelamin
                                        </div>
                                        <div class="detail-main-text">
                                            {{ genderPatient }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-12 mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">
                                            Status Kelahiran
                                        </div>
                                        <div class="detail-main-text">
                                            {{ birthStatus }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-12 mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">
                                            Catatan Tambahan
                                        </div>
                                        <div class="detail-main-text">
                                            {{ notes }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-4 col-sm-12 mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">
                                            Terakhir Periksa
                                        </div>
                                        <div class="detail-main-text">
                                            {{ last_check }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </section>

            <section id="history-checkup">
                <h3 class="mt-5">Riwayat Periksa</h3>
                <div class="row">
                    <div class="col-12">
                        <div
                            class="alert alert-danger"
                            role="alert"
                            v-bind:style="{ display: alertHistory }"
                        >
                            {{ alertMessageHistory }}
                        </div>
                        <div class="card p-3">
                            <div class="title mb-2">Pilih Tanggal Periksa</div>
                            <!-- <input type="text" name="daterange" class="form-control" v-model="filerDate" v-on:keydown="onFilter()"/> -->
                            <date-range-picker
                                ref="picker"
                                :opens="opens"
                                :locale-data="localData"
                                v-model="dateRange"
                                :showWeekNumbers="false"
                                :showDropdowns="true"
                                :linkedCalendars="false"
                                @update="update"
                            >
                                <template style="min-width: 350px">
                                    {{ dateRange.startDate }} -
                                    {{ dateRange.endDate }}
                                </template>
                            </date-range-picker>

                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Tanggal Periksa</th>
                                            <th>Berat Badan</th>
                                            <th>Tinggi Badan</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="loadHistory">
                                            <td colspan="6" class="text-center">
                                                <h1>
                                                    <i
                                                        class="
                                                            fa
                                                            fa-spinner
                                                            fa-spin
                                                        "
                                                    ></i>
                                                </h1>
                                            </td>
                                        </tr>
                                        <template
                                            v-for="(item, index) in listHistory"
                                            v-else
                                        >
                                            <tr
                                                class="align-middle"
                                                :key="item.id"
                                            >
                                                <td class="text-center">
                                                    {{ index + 1 }}
                                                </td>
                                                <td>
                                                    <span
                                                        v-html="
                                                            getTanggalPeriksa(
                                                                item.hasil_pemeriksaan
                                                            )
                                                        "
                                                    ></span>
                                                </td>
                                                <td>
                                                    <span
                                                        v-html="
                                                            getBerat(
                                                                item.hasil_pemeriksaan
                                                            )
                                                        "
                                                    ></span>
                                                </td>
                                                <td>
                                                    <span
                                                        v-html="
                                                            getTinggi(
                                                                item.hasil_pemeriksaan
                                                            )
                                                        "
                                                    ></span>
                                                </td>
                                                <td>
                                                    <button
                                                        v-on:click="onDetail(item.id, item.hasil_pemeriksaan)"
                                                        type="button"
                                                        class="
                                                            btn btn-details
                                                            my-2
                                                            text-nowrap
                                                        "
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#modalDetailPatient"
                                                    >
                                                        <i
                                                            class="
                                                                far
                                                                fa-edit
                                                                me-2
                                                            "
                                                        ></i
                                                        >Lihat Detail
                                                    </button>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div
            class="modal fade"
            v-bind:class="[isShowModal]"
            v-bind:style="{ display: displayModal }"
            id="modalPatient"
            tabindex="-1"
            aria-labelledby="modalPatientLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
        >
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content p-4">
                    <div class="d-flex">
                        <h4 class="d-inline fw-bold title-text mb-4">
                            Edit Pasien
                        </h4>
                        <button
                            type="button"
                            :disabled="inputRead"
                            v-on:click="closeModal()"
                            class="ms-auto btn-close"
                        ></button>
                    </div>

                    <h5 class="fw-bold title-text mb-3">
                        Identitas Orang Tua / Wali
                    </h5>
                    <form action="#" id="modal-form-patient">
                        <div
                            class="alert alert-danger"
                            role="alert"
                            v-bind:style="{ display: alertForm }"
                        >
                            {{ alertMessageForm }}
                        </div>
                        <div class="form-group mb-3">
                            <label for="birthStatus" class="mb-1"
                                >Nama Lengkap Orang Tua / Wali <code>(*)</code></label
                            >
                            <input
                                type="text"
                                required
                                :readonly="inputRead"
                                v-model="namePatient"
                                name="namePatient"
                                class="form-control"
                                placeholder="Masukkan Nama Lengkap"
                                id="namePatient"
                            />
                        </div>

                        <div class="form-group mb-3">
                            <label for="phonePatient" class="mb-1"
                                >No HP (WhatsApp) <code>(*)</code></label
                            >
                            <div class="row">
                                <div class="col-2 pe-1">
                                    <select
                                        name=""
                                        id=""
                                        class="form-select"
                                        required
                                        v-model="format_phone"
                                    >
                                        <option value="62">+62</option>
                                    </select>
                                </div>
                                <div class="col ps-1">
                                    <input
                                        type="number"
                                        required
                                        :readonly="inputRead"
                                        v-model="phonePatient"
                                        name="namePatient"
                                        class="form-control"
                                        placeholder="Contoh: 82123456789"
                                        id="phonePatient"
                                    />
                                </div>
                            </div>
                        </div>
                        <h5 class="fw-bold title-text mb-3">Identitas Anak</h5>
                        <div class="form-group mb-3">
                            <label for="childName" class="mb-1"
                                >Nama Lengkap Anak <code>(*)</code></label
                            >
                            <input
                                type="text"
                                required
                                :readonly="inputRead"
                                name="childName"
                                v-model="childName"
                                class="form-control"
                                placeholder="Masukkan Nama Anak"
                                id="childName"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <label for="birthdatePatient" class="mb-1"
                                >Tanggal Lahir <code>(*)</code></label
                            >
                            <input
                                type="date"
                                required
                                :readonly="inputRead"
                                name="birthdate"
                                v-model="birthdatePatient"
                                class="form-control"
                                placeholder="DD/MM/YYYY"
                                id="birthdatePatient"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <select
                                name="genderPatient"
                                id="genderPatient"
                                :readonly="inputRead"
                                v-model="genderPatients"
                                class="form-control mt-3"
                            >
                                <option value="">Pilih Jenis Kelamin</option>
                                <option value="L">Laki-Laki</option>
                                <option value="P">Perempuan</option>
                            </select>
                        </div>
                        <div class="form-group mb-3">
                            <label for="birthStatus" class="mb-1"
                                >Status Kelahiran</label
                            >
                            <input
                                type="text"
                                required
                                name="birthStatus"
                                :readonly="inputRead"
                                v-model="birthStatus"
                                class="form-control"
                                placeholder="Normal / Prematur / dll"
                                id="birthStatus"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <label for="notes" class="mb-1"
                                >Catatan Tambahan</label
                            >
                            <input
                                type="text"
                                required
                                :readonly="inputRead"
                                name="notes"
                                v-model="notes"
                                class="form-control"
                                placeholder="Alergic / Restriction"
                                id="notes"
                            />
                        </div>
                    </form>
                    <div>
                        <div class="d-grid">
                            <button
                                type="button"
                                :disabled="inputRead"
                                class="btn btn-main py-3 mb-3"
                                v-on:click="onSubmit()"
                            >
                                <i class="fa" v-bind:class="[spin]"></i> SIMPAN
                                DATA
                            </button>
                            <button
                                type="button"
                                :disabled="inputRead"
                                class="m-0 btn btn-edit"
                                v-on:click="closeModal()"
                            >
                                Tutup
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fade" v-bind:class="[backdrop]"></div>
        <detail-history  v-model="loadKalkulator" :pasien="dataPasien" :options="optionDetailHistory" :set_data="detailData" @on_result="onResultDetail"></detail-history>
    </div>
</template>
<script>
import DokterService from '@/services/DokterService';
import { getError } from '@/utils/helpers';

import DateRangePicker from 'vue2-daterange-picker';
import DetailHistory from '@/components/DetailHistory';
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: 'detail_pasien',
    components: { DateRangePicker, DetailHistory },
    data() {
        let startDate = new Date();
        let endDate = new Date();
        endDate.setDate(endDate.getDate() + 6);
        return {
            isShowModal: '',
            backdrop: '',
            displayModal: 'none',
            spin: '',
            loadHistory: true,
            listHistory: [],
            inputRead: false,
            namePatient: '',
            phonePatient: '',
            childName: '',
            birthdatePatient: '',
            genderPatient: '',
            genderPatients:'',
            birthStatus: '',
            format_phone:"62",
            notes: '',
            name: '',
            umur: '',
            date_birth: '',
            phone: '',
            last_check: '',
            alert: 'none',
            alertMessage: '',
            alertForm: 'none',
            alertMessageForm: '',
            alertHistory: 'none',
            alertMessageHistory: '',
            loadPasien: true,
            filerDate: 'Filter Date',
            startDate: '',
            endDate: '',
            opens: 'right',
            dateRange: { startDate, endDate },
            localData: {
                format: 'mm/dd/yyyy',
                separator: ' - ',
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                monthNames: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                ],
                firstDay: 1,
            },
            optionDetailHistory: {
                isShowModal: '',
                backdrop: '',
                displayModal: 'none',
                type:"dokter"
            },
            dataPasien:{
                parent:"",
                name:"",
                phone:"",
                id_history:"",
                gender:""
            },
            loadKalkulator:false,
            detailData: null,
        };
    },
    created() {
        this.onLoadPasien();
        this.$store.dispatch('auth/setMenuActive', { value: 'pasien' });
        document.title = 'Detail Pasien'
        let startDate = new Date();
        let endDate = new Date();
        endDate.setDate(endDate.getDate() + 6);
        this.startDate =
            startDate.getFullYear() +
            '-' +
            ('0' + (startDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + startDate.getDate()).slice(-2);
        this.endDate =
            endDate.getFullYear() +
            '-' +
            ('0' + (endDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + endDate.getDate()).slice(-2);
        this.onLoadHistory();
    },
    mounted() {
        //    window.$('input[name="daterange"]').daterangepicker({
        //       opens: "right",
        //       showDropdowns: true,
        //       buttonClasses : "btn",
        //       applyButtonClasses: "btnDate",
        //       cancelButtonClasses: "btnCancel",
        //   }, function(start, end, label) {
        //      var l = label;
        //      var s = start;
        //      var e = end;
        //      console.log(l);
        //      console.log(s);
        //      console.log(e);
        //   });
    },
    computed: {
        computeMarginLeft: function () {
            const width = window.innerWidth;
            if (width >= 768) {
                return '280px';
            }

            if (width < 768) {
                return '80px';
            }

            return '0px';
        },
    },
    methods: {
        async onLoadPasien() {
            this.loadPasien = true;
            await DokterService.detailPasien(this.$route.params.id)
                .then((response) => this.onResultPasien(response))
                .catch((error) => getError(error));
        },
        onResultPasien(response) {
            if (response.data.result == 'error') {
                this.alert = 'block';
                this.alertMessage = response.data.messsage;
            } else {
                this.last_check = response.data.data.last_check_format;
                this.umur = response.data.data.age;
                this.name = response.data.data.name;
                this.phone = response.data.data.phone;
                this.date_birth = response.data.data.birth_date_format;

                this.namePatient = response.data.data.parent_name;

                if(response.data.data.phone.substring(0,2) == "62") {
                    this.phonePatient = response.data.data.phone.slice(2,response.data.data.phone.length);
                }


              
                this.childName = response.data.data.name;
                this.birthdatePatient = response.data.data.birth_date;
                this.genderPatient = response.data.data.gender_format;
                this.genderPatients = response.data.data.gender;
                this.birthStatus = response.data.data.birth_status;
                this.notes = response.data.data.note;

                this.dataPasien.parent = response.data.data.parent_name;
                this.dataPasien.name = response.data.data.name;
                this.dataPasien.phone = response.data.data.phone;
                this.dataPasien.gender = response.data.data.gender;

                this.loadPasien = false;
            }
        },
        async onLoadHistory() {
            this.loadHistory = true;
            const payload = {
                params: {
                    date_1: this.startDate,
                    date_2: this.endDate,
                },
            };
            await DokterService.getHistory(payload, this.$route.params.id)
                .then((response) => this.onResultHistory(response))
                .catch((error) => getError(error));
        },
        onResultHistory(response) {
            if (response.data.result == 'error') {
                this.alertHistory = 'block';
                this.alertMessageHistory = response.data.messsage;
            } else {
                this.loadHistory = false;
                this.listHistory = response.data.data;
            }
        },
        getBerat(item) {
            var response = JSON.parse(item);
            return response.output.status_berat_badan.rawoutput;
        },
        getTinggi(item) {
            var response = JSON.parse(item);
            return response.output.status_tinggi_badan.rawoutput;
        },
        getTanggalPeriksa(item) {
            var response = JSON.parse(item);
            return response.output.tanggal_periksa;
        },
        onDetail(id,item) {
            var response = JSON.parse(item);
            this.dataPasien.id_history = id
            this.detailData = response
            this.openDetailHistory()
        },
        openModal() {
            if (this.isShowModal == 'show') {
                this.isShowModal = '';
                this.displayModal = 'none';
                this.backdrop = '';
            } else {
                this.isShowModal = 'show';
                this.displayModal = 'block';
                this.backdrop = 'modal-backdrop show';
            }
        },
        closeModal() {
            this.backdrop = '';
            this.isShowModal = '';
            this.displayModal = 'none';
        },

        async onSubmit() {
            this.spin = 'fa-spinner fa-spin';
            this.inputRead = true;
            const payload = {
                id: this.$route.params.id,
                parent_name: this.namePatient,
                phone: this.format_phone+""+this.phonePatient,
                name: this.childName,
                birth_date: this.birthdatePatient,
                gender: this.genderPatients,
                birth_status: this.birthStatus,
                note: this.notes,
            };

            await DokterService.updatePasien(payload)
                .then((response) => this.onSubmitResponse(response))
                .catch((error) => getError(error));
        },
        onSubmitResponse(response) {
            console.log(response);
            if (response.data.result == 'error') {
                this.spin = '';
                this.inputRead = false;
                this.alertForm = 'block';
                this.alertMessageForm = response.data.message;
            } else {
                this.spin = '';
                this.inputRead = false;
                this.openModal();
                this.onLoadPasien();
            }
        },
        update() {
            const start = new Date(this.dateRange.startDate);
            const startFormat =
                start.getFullYear() +
                '-' +
                ('0' + (start.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + start.getDate()).slice(-2);

            const end = new Date(this.dateRange.endDate);
            const endtFormat =
                end.getFullYear() +
                '-' +
                ('0' + (end.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + end.getDate()).slice(-2);

            this.startDate = startFormat;
            this.endDate = endtFormat;

            this.onLoadHistory();
        },
        openDetailHistory() {
            this.loadKalkulator = true
            if (this.optionDetailHistory.isShowModal == 'show') {
                this.optionDetailHistory.isShowModal = '';
                this.optionDetailHistory.displayModal = 'none';
                this.optionDetailHistory.backdrop = '';
            } else {
                this.optionDetailHistory.isShowModal = 'show';
                this.optionDetailHistory.displayModal = 'block';
                this.optionDetailHistory.backdrop = 'modal-backdrop show';
            }
        },
        onResultDetail(val) {
            this.loadKalkulator = val
        },
        back() {
            this.$router.go(-1)
        }
    },
};
</script>
