<template>
    <div class="container-fluid">
        <div
            class="page-content ml-sm-0"
            id="page-content"
        >
            <div class="header-greeting mb-3">
                <p class="p-0 m-0">Recopedia</p>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <h1 class="fw-bolder">Pasien</h1>
                    </div>
                    <div class="col-sm-12 col-md-6 text-md-end">
                        <button
                            type="button"
                            class="btn btn-add-main"
                            v-on:click="openModal()"
                        >
                            Tambah Pasien
                        </button>
                    </div>
                </div>
            </div>
            <div class="patient-card mb-4">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="card" style="min-height: auto">
                            <div class="card-body pb-0">
                                <div class="text-end">
                                    <div class="title mb-2">
                                        Urutkan Berdasarkan
                                    </div>
                                </div>
                                <form @submit.prevent="onLoadPasien">
                                    <div class="row filter-input">
                                        <div class="col-md-7 mb-2">
                                            <div class="search-input">
                                                <input
                                                    type="text"
                                                    name="patient"
                                                    id="patient-search"
                                                    v-model="patientSearch"
                                                    v-on:keyenter="onFilter()"
                                                    class="form-control"
                                                    placeholder="Cari Pasien"
                                                />
                                                <i class="fas fa-search"></i>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-6 mb-2">
                                            <select
                                                name="name"
                                                id="sortName"
                                                v-model="sortName"
                                                v-on:change="onFilter()"
                                                class="form-select"
                                            >
                                                <option value="parent_name">
                                                    Nama Orang Tua
                                                </option>
                                                <option value="name">
                                                    Nama Anak
                                                </option>
                                                <option value="birth_date">
                                                    Tanggal Lahir
                                                </option>
                                                <option value="last_check">
                                                    Tanggal Periksa
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-2 col-6 mb-2">
                                            <select
                                                name="alphaName"
                                                id="alphaName"
                                                v-model="alphaName"
                                                v-on:change="onFilter()"
                                                class="form-select"
                                            >
                                                <option value="asc">
                                                    A - Z
                                                </option>
                                                <option value="desc">
                                                    Z - A
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body pt-0 px-0">
                                <div class="table-responsive">
                                    <!-- Should be DataTables -->
                                    <table class="table">
                                        <thead>
                                            <tr class="text-left">
                                                <th>No</th>
                                                <th>Nama Lengkap</th>
                                                <th>Tanggal Lahir</th>
                                                <th>Umur</th>
                                                <th>No HP</th>
                                                <th>Terakhir Periksa</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loadPasien">
                                                <td
                                                    colspan="7"
                                                    class="text-center"
                                                >
                                                    <h1>
                                                        <i
                                                            class="
                                                                fa
                                                                fa-spinner
                                                                fa-spin
                                                            "
                                                        ></i>
                                                    </h1>
                                                </td>
                                            </tr>
                                            <template
                                                v-for="(
                                                    item, index
                                                ) in listPasien"
                                                v-else
                                            >
                                                <tr
                                                    class="
                                                        align-middle
                                                        text-left
                                                    "
                                                    :key="item.id"
                                                >
                                                    <td>{{ index + 1 }}</td>
                                                    <td>{{ item.name }}</td>
                                                    <td>
                                                        {{
                                                            item.birth_date_format
                                                        }}
                                                    </td>
                                                    <td>{{ item.age }}</td>
                                                    <td>{{ item.phone }}</td>
                                                    <td>
                                                        {{
                                                            item.last_check_format
                                                        }}
                                                    </td>
                                                    <td>
                                                        <router-link
                                                            :to="{
                                                                name: 'pasien_detail',
                                                                params: {
                                                                    id: item.id,
                                                                },
                                                            }"
                                                            style="
                                                                text-decoration: none;
                                                            "
                                                        >
                                                            <button
                                                                type="button"
                                                                class="
                                                                    d-inline
                                                                    btn
                                                                    btn-details
                                                                    my-1
                                                                    me-2
                                                                "
                                                            >
                                                                <i
                                                                    class="
                                                                        far
                                                                        fa-eye
                                                                        me-2
                                                                    "
                                                                ></i
                                                                >Lihat
                                                            </button>
                                                        </router-link>
                                                        <button
                                                            disabled
                                                            v-if="
                                                                loadDelete ==
                                                                    true &&
                                                                itemId ==
                                                                    item.id
                                                            "
                                                            type="button"
                                                            class="
                                                                d-inline
                                                                btn btn-delete
                                                                my-1
                                                            "
                                                        >
                                                            <i
                                                                class="
                                                                    fa
                                                                    fa-spinner
                                                                    fa-spin
                                                                "
                                                            ></i>
                                                            Hapus
                                                        </button>
                                                        <button
                                                            v-else
                                                            type="button"
                                                            v-on:click="
                                                                onDeletePasienConfirm(
                                                                    item
                                                                )
                                                            "
                                                            class="
                                                                d-inline
                                                                btn btn-delete
                                                                my-1
                                                            "
                                                        >
                                                            Hapus
                                                        </button>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="table-info" v-if="paging_show">
                                    <h6 class="mb-0 text-muted">
                                        <small>Rows per page: </small>
                                    </h6>
                                    <div class="dropdown-container">
                                        <select
                                            name="rows"
                                            id="pageRow"
                                            class="form-select page-select"
                                            v-on:change="onChangePerpage()"
                                            v-model="perpage"
                                        >
                                            <option value="7">7</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                        </select>
                                        <!-- <i class="fas fa-chevron-down"></i> -->
                                    </div>
                                    <div class="mx-3 paging-of">
                                        <small>{{ page_info }}</small>
                                    </div>
                                    <button
                                        class="btn"
                                        :disabled="disabled_prev"
                                        v-on:click="prevPage()"
                                    >
                                        <i class="fas fa-chevron-left"></i>
                                    </button>
                                    <button
                                        class="btn"
                                        :disabled="disabled_next"
                                        v-on:click="nextPage()"
                                    >
                                        <i class="fas fa-chevron-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <confirm :options="optionConfirm" :confirm="confirm_delete" @on_listen_confirm="onListenConfirm"></confirm>
        <div
            class="modal fade"
            v-bind:class="[isShowModal]"
            v-bind:style="{ display: displayModal }"
            id="modalPatient"
            tabindex="-1"
            aria-labelledby="modalPatientLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
        >
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content p-4">
                    <div class="d-flex">
                        <h4 class="d-inline fw-bold title-text mb-4">
                            Tambah Pasien
                        </h4>
                        <button
                            type="button"
                            :disabled="inputRead"
                            v-on:click="closeModal()"
                            class="ms-auto btn-close"
                        ></button>
                    </div>

                    <h5 class="fw-bold title-text mb-3">
                        Identitas Orang Tua / Wali
                    </h5>
                    <form action="#" id="modal-form-patient">
                        <div
                            class="alert alert-danger"
                            role="alert"
                            v-bind:style="{ display: alert }"
                        >
                            {{ alertMessage }}
                        </div>
                        <div class="form-group mb-3">
                            <label for="birthStatus" class="mb-1"
                                >Nama Lengkap Orang Tua / Wali</label
                            >
                            <input
                                type="text"
                                required
                                :readonly="inputRead"
                                v-model="namePatient"
                                name="namePatient"
                                class="form-control"
                                placeholder="Masukkan Nama Lengkap"
                                id="namePatient"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <label for="phonePatient" class="mb-1"
                                >No HP (WhatsApp)</label
                            >
                            <input
                                type="text"
                                required
                                :readonly="inputRead"
                                name="phonePatient"
                                v-model="phonePatient"
                                class="form-control"
                                placeholder="Masukkan Nomer Handphone"
                                id="phonePatient"
                            />
                        </div>
                        <h5 class="fw-bold title-text mb-3">Identitas Anak</h5>
                        <div class="form-group mb-3">
                            <label for="childName" class="mb-1"
                                >Nama Lengkap Anak</label
                            >
                            <input
                                type="text"
                                required
                                :readonly="inputRead"
                                name="childName"
                                v-model="childName"
                                class="form-control"
                                placeholder="Masukkan Nama Anak"
                                id="childName"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <label for="birthdatePatient" class="mb-1"
                                >Tanggal Lahir</label
                            >
                            <input
                                type="date"
                                required
                                :readonly="inputRead"
                                name="birthdate"
                                v-model="birthdatePatient"
                                class="form-control"
                                placeholder="DD/MM/YYYY"
                                id="birthdatePatient"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <select
                                name="genderPatient"
                                id="genderPatient"
                                :readonly="inputRead"
                                v-model="genderPatient"
                                class="form-control mt-3"
                            >
                                <option value="">Pilih Jenis Kelamin</option>
                                <option value="L">Laki-Laki</option>
                                <option value="P">Perempuan</option>
                            </select>
                        </div>
                        <div class="form-group mb-3">
                            <label for="birthStatus" class="mb-1"
                                >Status Kelahiran</label
                            >
                            <input
                                type="text"
                                required
                                name="birthStatus"
                                :readonly="inputRead"
                                v-model="birthStatus"
                                class="form-control"
                                placeholder="Normal / Prematur / dll"
                                id="birthStatus"
                            />
                        </div>
                        <div class="form-group mb-3">
                            <label for="notes" class="mb-1"
                                >Catatan Tambahan</label
                            >
                            <input
                                type="text"
                                required
                                :readonly="inputRead"
                                name="notes"
                                v-model="notes"
                                class="form-control"
                                placeholder="Alergic / Restriction"
                                id="notes"
                            />
                        </div>
                    </form>
                    <div>
                        <div class="d-grid">
                            <button
                                type="button"
                                :disabled="inputRead"
                                class="btn btn-main py-3"
                                v-on:click="onSubmit()"
                            >
                                <i class="fa" v-bind:class="[spin]"></i> SIMPAN
                                DATA
                            </button>
                            <button
                                type="button"
                                :disabled="inputRead"
                                class="m-0 btn btn-link"
                                v-on:click="closeModal()"
                            >
                                Tutup
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fade" v-bind:class="[backdrop]" ></div>
        <add-pasien
            :options="optionAddPasien"
            @reload_emit="onReloadPasien"
        ></add-pasien>
    </div>
</template>
<script>
import DokterService from "@/services/DokterService";
import { getError } from "@/utils/helpers";
import Confirm from "@/components/Confirm";
import AddPasien from '@/components/AddPasien';

export default {
    name: 'Pasien',
    components:{Confirm,AddPasien},
    data() {
        return {
            isShowModal: '',
            backdrop: '',
            displayModal: 'none',
            spin: '',
            inputRead: false,
            listPasien: [],
            namePatient: '',
            phonePatient: '',
            childName: '',
            birthdatePatient: '',
            genderPatient: '',
            birthStatus: '',
            notes: '',
            alert: 'none',
            alertMessage: '',
            alphaName: 'asc',
            sortName: 'parent_name',
            loadPasien: true,
            patientSearch: '',
            page: 1,
            perpage: 7,
            last_page: 1,
            page_info: '',
            index_data: 1,
            paging_show:false,
            disabled_next: false,
            disabled_prev: false,
            loadDelete: false,
            itemId: 0,
            optionConfirm:{
                isShowModal: '',
                backdrop: '',
                displayModal: 'none',
                caption:"",
            },
            optionAddPasien: {
                isShowModal: '',
                backdrop: '',
                displayModal: 'none',
                returnResult: true,
            },
            confirm_delete:false,
        };
    },
    created: function () {
        this.$store.dispatch('auth/setMenuActive', { value: 'pasien' });
        document.title = 'Pasien'
    },
    mounted() {
        this.onLoadPasien();
    },
    computed: {
        computeMarginLeft: function () {
            const width = window.innerWidth;
            if (width >= 768) {
                return '280px';
            }

            if (width < 768) {
                return '80px';
            }

            return '0px';
        },
    },
    methods: {
        resizeToesizeListener() {},
        async onLoadPasien() {
            this.loadPasien = true;
            const payload = {
                params: {
                    order: this.sortName,
                    sort: this.alphaName,
                    search: this.patientSearch,
                    perpage: this.perpage,
                    page: this.page,
                },
            };
            await DokterService.getPasien(payload)
                .then((response) => this.onResultPasien(response))
                .catch((error) => getError(error));
        },
        onResultPasien(response) {
            this.listPasien = response.data.data;
            this.loadPasien = false;
            this.last_page = response.data.last_page;
            if(this.listPasien.length > 0) {
                this.paging_show = true
            } else {
                this.paging_show = false
            }

            this.page_info =
                response.data.current_page +
                '-' +
                response.data.last_page +
                ' of ' +
                response.data.to;
            this.index_data = response.data.from;

            if (this.page == 1) {
                this.disabled_prev = true;
            } else {
                this.disabled_prev = false;
            }

            if (this.page < this.last_page) {
                this.disabled_next = false;
            } else {
                this.disabled_next = true;
            }
        },
        openModal() {
            // if (this.isShowModal == 'show') {
            //     this.isShowModal = '';
            //     this.displayModal = 'none';
            //     this.backdrop = '';
            // } else {
            //     this.isShowModal = 'show';
            //     this.displayModal = 'block';
            //     this.backdrop = 'modal-backdrop show';
            // }
            if (this.optionAddPasien.isShowModal == 'show') {
                this.optionAddPasien.isShowModal = '';
                this.optionAddPasien.displayModal = 'none';
                this.optionAddPasien.backdrop = '';
                this.optionAddPasien.returnResult = false;
            } else {
                this.optionAddPasien.returnResult = false;
                this.optionAddPasien.isShowModal = 'show';
                this.optionAddPasien.displayModal = 'block';
                this.optionAddPasien.backdrop = 'modal-backdrop show';
            }
        },
        closeModal() {
            this.backdrop = '';
            this.isShowModal = '';
            this.displayModal = 'none';
        },
        async onSubmit() {
            this.spin = 'fa-spinner fa-spin';
            this.inputRead = true;
            const payload = {
                parent_name: this.namePatient,
                phone: this.phonePatient,
                name: this.childName,
                birth_date: this.birthdatePatient,
                gender: this.genderPatient,
                birth_status: this.birthStatus,
                note: this.notes,
            };

            await DokterService.addPasien(payload)
                .then((response) => this.onSubmitResponse(response))
                .catch((error) => getError(error));
        },
        onSubmitResponse(response) {
            if (response.data.result == 'error') {
                this.spin = '';
                this.inputRead = false;
                this.alert = 'block';
                this.alertMessage = response.data.message;
            } else {
                this.spin = '';
                this.inputRead = false;
                this.openModal();
                this.onLoadPasien();
            }
        },
        onFilter() {
            this.page = 1;
            this.onLoadPasien();
        },
        async onDeletePasienConfirm(item) {

            if (this.optionConfirm.isShowModal == 'show') {
                this.optionConfirm.isShowModal = '';
                this.optionConfirm.displayModal = 'none';
                this.optionConfirm.backdrop = '';
                this.optionConfirm.caption = ""
            } else {
                this.optionConfirm.caption ="Apakah anda yakin akan menghapus pasien <b>"+item.name+"</b> ?"
                this.optionConfirm.isShowModal = 'show';
                this.optionConfirm.displayModal = 'block';
                this.optionConfirm.backdrop = 'modal-backdrop show';
            }
            this.itemId = item.id;
            // this.loadDelete = true;

            // const payload = {
            //     data: {
            //         id: this.itemId,
            //     },
            // };

            // await DokterService.deletePasien(payload)
            //     .then((response) => this.onDeleteResponse(response))
            //     .catch((error) => getError(error));
        },
        async onDeletePasien() {
            this.loadDelete = true;

            const payload = {
                data: {
                    id: this.itemId,
                },
            };

            await DokterService.deletePasien(payload)
                .then((response) => this.onDeleteResponse(response))
                .catch((error) => getError(error));
        },
        onDeleteResponse(response) {
            if (response.data.result == 'success') {
                this.loadDelete = false;
                this.onLoadPasien();
            }
        },
        onListenConfirm(val){
 
            if(val == true) {
                this.onDeletePasien()
            }
        },
        nextPage() {
            if (this.page < this.last_page) {
                this.page++;
                this.onLoadPasien();
            }

            if (this.page < this.last_page) {
                this.disabled_next = false;
            } else {
                this.disabled_next = true;
            }
        },
        prevPage() {
            if (this.page > 1) {
                this.page--;
                this.onLoadPasien();
            }

            if (this.page == 1) {
                this.disabled_prev = true;
            } else {
                this.disabled_prev = false;
            }
        },
        onChangePerpage() {
            this.page = 1;
            this.onLoadPasien();
        },
         onReloadPasien(result) {
            // this.reload = result;
            if(result) {
                this.onLoadPasien()
            }
        },
    },
};
</script>
